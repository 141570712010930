/* Custom CSS */

.navbar.default-layout-navbar .navbar-brand-wrapper .brand-logo img {
    width: unset;
    height: 60px;
}

.table tbody .action-buttons {
    text-align: right;
}

.top-buttons .btn {
    margin-right: .3em;
}

.btn-table {
    min-width: 7em;
    font-size: .9em !important; 
}

.pick-list-questions-lenght {
    margin-right: 1em;
}

.pick-list-add-btn {
    float: left;
}

.pick-list-length-ok {
    color: green;
}

.pick-list-length-nok {
    color: red;
}

.p-dialog-content{
    border: 0;
    background-color: #f2f2f2;
}
.p-picklist.p-picklist-responsive .p-picklist-listwrapper {
    width: 48%;
}

.p-picklist .p-picklist-buttons {
    width: 3% !important;
}

.p-picklist .p-button-text {
    min-width: 2em;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding-left: 3px;
    padding-right: 3px;
}

.p-widget {
    font-family: PTSans, sans-serif !important;
    font-size: .9em !important;
}

.p-inputtext {
    border: 1px solid #e0e0ef;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: 1em;
}

.p-picklist-item .p-highlight {
    color: #ffffff !important;
}

body .p-dialog .p-dialog-content {
    background-color: #f2f2f2;
    border: 0px;
}

/**************************
  FORM
**************************/

.form-control {
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: 1em;
}

.auth .brand-logo img {
    width: 20em;
}

/**************************
  PROGRESS BAR
**************************/

.CircularProgressbar {
    width: 15em;
}
  
/**************************
  UPLOAD FILE
**************************/

.p-fileupload-choose {
    width: 20em;
}

.p-button-text {
    min-width: 8em;
}

/**************************
  INPUT SWITCH
**************************/

.p-inputswitch-slider {
    height: 1.2rem;
}

.p-inputswitch-slider::before {
    height: .9rem !important;
    width: .9rem !important;
    bottom: .15rem !important;
}