.o-group-create-button-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.o-group-create-info {
    margin-bottom: 1rem;
}

.o-group-create-picklist-filter {
    display: flex;
    flex-direction: row;
    margin-bottom: .3em;
}

.o-group-create-picklist-filter-center {
    width: 4%;
}

.o-group-create-picklist-filter-left {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 48%;
    flex-wrap: wrap;
}

.o-group-create-picklist-filter-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 48%;
    flex-wrap: wrap;
}
