html, body {
    height: 100% !important;
}

#root {
    height: 100% !important;
}

/**************************
  USER CREATE
**************************/

.opp-user-create-header {
    margin-bottom: 1em;
}

.opp-user-create-body {
    min-height: 17em;
}

.opp-user-create-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
}

.opp-user-create-footer button {
    margin-left: 1em;
}

/**************************
  PAGE
**************************/

.opp-page-title {
    margin-bottom: 1em;
}

.opp-page-title .btn-icon {
    width: 2em;
    height: 2em;
    margin-left: .5em;
}

.opp-page-title i {
    color: #ffffff;
}

/**************************
  BOX
**************************/

.opp-box {
    display: inline-block;
    border: solid 1px #000000;
    height: 10em;
    margin: .5em;
    width: 10em;
}

.opp-company-admin {
    margin-bottom: 3em;
}

.opp-company-admin .card {
    background: #3a3f51;
}

.opp-box-admin {
    display: inline-block;
    margin: .5em;
}

/**************************
  DEPARTMENT
**************************/

.opp-department-role-item {
    margin-bottom: .5em;
}

.opp-btn-danger {
    background: #ff5e6d !important;
}

.opp-shelf-no-department {
    display: flex;
    text-align: center;
    height: 11em;
}

.opp-shelf-no-department-bloc {
    margin: auto;
}

.opp-create-dep-create-user {
    text-align: right;
    margin-top: .9em;
}

.opp-create-dep-create-user button {
    margin-right: 0 !important;
}

/**************************
  PICK LIST
**************************/

.opp-picklist {
    margin-bottom: 1em;
}

.opp-picklist .ui-picklist-caption {
    background: #392C70;
    border: 1px solid #392C70;
    color: #ffffff;
}

.opp-picklist .ui-picklist-listwrapper {
    width: 48% !important;
}

.opp-picklist .ui-picklist-list {
    border-top: 1px solid #392C70;
    padding: .8em 1em .8em 1em;
}

.opp-picklist .ui-picklist-item {
    display: block;
    border: 1px solid #a9a9a9;
    border-radius: 3px;
    padding: .8em !important;
    margin-bottom: .5em !important;
    text-align: center;
}

.opp-picklist .ui-state-highlight {
    border-color: #a9a9a9;
    background: #a9a9a9;
    color: #FFFFFF;
}

.opp-picklist .ui-picklist-buttons {
    width: 4% !important;
}

.opp-picklist .ui-button {
    background: #392C70;
    border-color: #392C70 !important;
}

.opp-picklist .ui-button-text {
    background: #392C70;
    border-color: #392C70 !important;
}

/**************************
  BUTTON
**************************/

.opp-btn-back {
    background-color: #ffffff !important;
}

.opp-btn-back i {
    color: #392C70 !important;
}

/**************************
  STEP
**************************/

.opp-step {
    display: flex;
}

.opp-step-item {
    background-color: #dedede;
    border-radius: .3em;
    display: flex;
    height: 3em;
    margin-right: 1em;
    width: 10em;
}

.opp-step-label {
    margin: auto;
}

.opp-step-actif {
    background-color: #0b94f7;
    color: #ffffff;
}

.opp-quiz-question-collapse-container {
    display: flex;
}

.opp-quiz-question-collapse {
    align-self: flex-end;
    margin-bottom: 24px;
    height: 2.3em;
}

.opp-input-file img {
    height: 8em;
}
